// Reexport all svg icons

export { default as addPlant } from './addPlant.svg'
export { default as airhumidity } from './airhumidity.svg'
export { default as arrow } from './arrow.svg'
export { default as arrowleft } from './arrowleft.svg'
export { default as calendar } from './calendar.svg'
export { default as climbers } from './climbers.svg'
export { default as close } from './close.svg'
export { default as duplicate } from './duplicate.svg'
export { default as flowering } from './flowering.svg'
export { default as germination } from './germination.svg'
export { default as globe } from './globe.svg'
export { default as go } from './go.svg'
export { default as growing } from './growing.svg'
export { default as heart } from './heart.svg'
export { default as heartFilled } from './heartFilled.svg'
export { default as imageIllustration } from './imageIllustration.svg'
export { default as lock } from './lock.svg'
export { default as message } from './message.svg'
export { default as mositure } from './mositure.svg'
export { default as pdfIllustration } from './pdfIllustration.svg'
export { default as pencil } from './pencil.svg'
export { default as pending } from './pending.svg'
export { default as plant } from './plant.svg'
export { default as planttemprature } from './planttemprature.svg'
export { default as questionMark } from './questionMark.svg'
export { default as reject } from './reject.svg'
export { default as ripening } from './ripening.svg'
export { default as seedling } from './seedling.svg'
export { default as soilfertilization } from './soilfertilization.svg'
export { default as success } from './success.svg'
export { default as sun } from './sun.svg'
export { default as temperature } from './temperature.svg'
export { default as user } from './user.svg'
export { default as videoIllustration } from './videoIllustration.svg'
export { default as world } from './world.svg'
